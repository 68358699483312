import { InMemoryCache } from "@apollo/client";
import possibleTypes from "./../../public/data/possibleTypes.json";

const inMemoryCache = new InMemoryCache({
  possibleTypes,
  resultCacheMaxSize: Math.pow(2, 20),
  /**
   * typePolicies are used to define how the cache should handle the data for each type.
   * This is needed, if Apollo Client doesn't correctly merge the data of different queries
   * for the same type and dataset (e.g. teaser query and detail page query). Incorrect 
   * auto-merging results in overwriting cached data again and again.
   * @see https://www.apollographql.com/docs/react/caching/cache-field-behavior/
   * @see https://www.apollographql.com/docs/react/pagination/core-api/
   */
  typePolicies: {
    Query: {
      fields: {
        entityById: {
          keyArgs: ["entityType", "id"],
          merge(existing = {}, incoming) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
    View: {
      fields: {
        executable: {
          merge(existing = {}, incoming) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
    // Apollo cache doesn't correctly merge NodeParticipant images.
    // This custom merge function merges the fieldImage and fieldMediaImage fields.
    NodeParticipant: {
      fields: {
        fieldImage: {
          merge(existing = {}, incoming) {
            return {
              ...existing,
              ...incoming,
              fieldMediaImage: {
                ...(existing?.fieldMediaImage || {}),
                ...(incoming?.fieldMediaImage || {}),
              },
            };
          },
        },
      },
    },
    // Apollo cache doesn't correctly merge NodeNews images.
    // This custom merge function merges the fieldImage and fieldMediaImage fields.
    NodeNews: {
      fields: {
        fieldImage: {
          merge(existing = {}, incoming) {
            return {
              ...existing,
              ...incoming,
              fieldMediaImage: {
                ...(existing?.fieldMediaImage || {}),
                ...(incoming?.fieldMediaImage || {}),
              },
            };
          },
        },
      },
    },
  },
});

export default inMemoryCache;
